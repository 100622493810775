import { http } from "../config";
// const login = "sessions"
// const users = "users/"

export default {
  sessions: async (dataLogin) => {
    return await http.post("sessions", dataLogin);
  },

  findUserById: (id, unidade) => {
    return http.get(`users/${id}?unidade=${unidade}`);
  },

  listUsers: (unidade) => {
    return http.get(`users?unidade=${unidade}`);
  },

  registerUser: async (data, unidade) => {
    return await http.post(`users?unidade=${unidade}`, data);
  },

  validate: (unidade) => {
    return http.get(`validateToken?unidade=${unidade}`);
  },

  updateUserById: (dataUpdateUser, unidade) => {
    return http.put(`users?unidade=${unidade}`, dataUpdateUser);
  },

  changeStatus: (userStatus) => {
    return http.patch("users", userStatus);
  },

  deleteUser: async (id, unidade) => {
    return await http.delete(`users/${id}?unidade=${unidade}`);
  },
};
