import axios from "axios";
import Cookie from "js-cookie";

const http = axios.create({
  baseURL: "https://api.startup.tutilabs.com.br",
  // baseURL: "http://localhost:8200",
  headers: {
    Accept: "application/json",
    Content: "application/json",
  },
});

http.interceptors.request.use(
  function (config) {
    let token = Cookie.get("token");

    if (!token) {
      token = "";
    }

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
export { http };
